<template>
  <v-app-bar
    app
    clipped-left
    flat
    class="app-menu-wrap"
    color="white"
    height="70"
  >
    <v-app-bar-nav-icon
      v-if="$vuetify.breakpoint.smAndDown && links"
      @click="$store.dispatch('toggleSidebarMenu')"
    ></v-app-bar-nav-icon>

    <div class="app-menu">
      <div class="d-flex align-center">
        <router-link
          :to="{ name: 'MainPageLanding' }"
          class="shrink mr-auto ml-auto mr-md-11 ml-md-0"
        >
          <v-img
            alt="Vuetify Logo"
            class="app-menu-logo"
            contain
            :src="require('@/assets/img/logo-dark.svg')"
          />
        </router-link>

        <template v-if="links">
          <ul
            class="d-flex align-center app-menu-list app-menu-primary-links"
            v-if="$vuetify.breakpoint.mdAndUp"
          >
            <li class="mr-11">
              <router-link
                :to="{ name: 'HowItWorksLanding' }"
                class="black--text text-decoration-none text-uppercase"
                >How it works</router-link
              >
            </li>
            <li class="mr-11">
              <router-link
                :to="{ name: 'CatalogLanding' }"
                class="black--text text-decoration-none text-uppercase"
                >Catalog</router-link
              >
            </li>
            <li>
              <router-link
                :to="{ name: 'WhyUsLanding' }"
                class="black--text text-decoration-none text-uppercase"
                >Why Us</router-link
              >
            </li>
          </ul>

          <ul class="d-flex align-center ml-md-auto app-menu-list">
            <template v-if="$vuetify.breakpoint.mdAndUp">
<!--              <li>-->
<!--                <router-link-->
<!--                  :to="{ name: null }"-->
<!--                  class="act-text purple&#45;&#45;text text-decoration-none text-uppercase mr-5"-->
<!--                  >Pricing</router-link-->
<!--                >-->
<!--              </li>-->
              <li>
                <router-link :to="{ name: 'Login' }" class="act-text purple--text text-decoration-none text-uppercase mr-9">
                  Log In
                </router-link>
              </li>
            </template>
            <li>
              <v-btn
                dark
                depressed
                height="auto"
                color="black"
                class="font-weight-bold btn-default px-sm-5 py-sm-2 py-1 px-2 start-btn"
                :to="{ name: 'Register' }"
              >
                Sign up
                <img
                  class="ml-2"
                  width="24"
                  height="24"
                  :src="require('@/assets/img/icons/ic-arrow-right-white.svg')"
                  alt=""
                />
              </v-btn>
            </li>
          </ul>
        </template>
      </div>
    </div>

    <v-spacer></v-spacer>
  </v-app-bar>
</template>

<script>
export default {
  name: "AppMenu",
  props: {
    links: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/_colors";

.app-menu-wrap.v-toolbar {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1) !important;
  z-index: 9999 !important;
  .v-toolbar__content {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.app-menu {
  width: 100%;

  & > div {
    margin-left: 19px;
    margin-right: 30px;
    height: 100%;
  }

  .app-menu-list {
    list-style-type: none;
    padding: 0;
    margin-left: 8px;
  }

  .app-menu-primary-links a {
    position: relative;
    padding: 12px 0;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.75px;

    &:hover::after,
    &.router-link-exact-active::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: -18px;
      width: 100%;
      height: 4px;
      border-radius: 4px;
      background-color: $primary;
    }
  }
}

.app-menu-logo {
  width: 120px;
}

.start-btn {
  border-radius: 4px !important;

  span {
    font-size: 16px;
    line-height: 22px;
    font-weight: normal;
  }
}

@media screen and (max-width: 1440px) {
  .app-menu-logo {
    width: 100px;
  }
}

@media screen and (max-width: 960px) {
  .app-menu-wrap.v-toolbar {
    height: 65px !important;
    .v-toolbar__content {
      height: 65px !important;
    }
  }
  .v-application--wrap > .v-main {
    padding-top: 65px !important;
  }
  .app-menu-logo {
    width: 120px;
  }
}

@media screen and (max-width: 767px) {
  .app-menu > div {
    margin-right: 0;
  }
}

.router-link-exact-active {
  font-weight: bold;
}

.act-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.75px;
}
</style>
