import httpClient from "@/api/httpClient";

const API = 'https://admin.joinroxie.com/shopify_api/v1';
const END_POINT = `${API}/roxie_marketing`;

export const checkUserExist = data => httpClient.post(`https://admin-staging.joinroxie.com/shopify_api/v1/roxie_marketing/check_user_exist`, data);

export const registerUser = data => httpClient.post(`${END_POINT}/register`, JSON.stringify(data));

export const loginUser = data => httpClient.post(`${END_POINT}/login`, JSON.stringify(data));

// export const loadConnectPage = () => httpClient.get('https://app.joinroxie.com/companies');
