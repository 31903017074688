import Vue from 'vue'
import Vuex from 'vuex'
import sidebar from '@/store/modules/sidebar.store'
import authorizations from '@/store/modules/authorizations.store'
import products from '@/store/modules/products.store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    sidebar,
    products,
    auth: authorizations,
  }
})
