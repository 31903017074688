<template>
  <div v-if="$vuetify.breakpoint.smAndDown">
    <v-navigation-drawer
      v-model="$store.state.sidebar.menu"
      app
    >
      <v-list flat style="padding-top: 73px;">
        <v-list-item-group
          v-model="group"
          color="primary"
        >
          <v-list-item link exact v-for="(link, index) in menuLinks" :key="index" :to="{name: link.routeName}">
            <v-list-item-title>{{link.title}}</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: 'Sidebar',
  data: () => ({
    group: null,
    menuLinks: [
      { title:"How it works", routeName: 'HowItWorksLanding' },
      { title:"Catalog", routeName: 'CatalogLanding' },
      { title:"Why us", routeName: 'WhyUsLanding' },
      // { title:"Pricing", routeName: 'MainPageLanding' },
      { title:"Log In", routeName: 'Login' },
    ]
  }),
}
</script>
