export default {
  state: {
    menu: false,
  },
  mutations: {
    SET_MENU_STATUS(state, playload) {
      state.menu = playload;
    }
  },
  actions: {
    toggleSidebarMenu({commit, state}) {
      commit('SET_MENU_STATUS', !state.menu);
    }
  }
}
