import {getAllProducts, getAllCategories} from "@/api/products.api";

export default {
  state: {
    allProducts: [],
    allCategories: [],
  },
  getters: {},
  mutations: {
    SET_PRODUCTS(state, payload) {
      state.allProducts = [...payload];
    },
    SET_CATEGORIES(state, payload) {
      state.allCategories = [...payload];
    },
  },
  actions: {
    async LOAD_PRODUCTS({commit}) {
      try {
        const res = await getAllProducts();
        commit('SET_PRODUCTS', res.data.data);
      } catch (error) {
        console.error(`Loading products list failed with error: ${error.message}`);
      }
    },
    async LOAD_CATEGORIES({commit}) {
      try {
        const res = await getAllCategories();
        commit('SET_CATEGORIES', res.data.data);
      } catch (error) {
        console.error(`Loading categories list failed with error: ${error.message}`);
      }
    }
  }
}
