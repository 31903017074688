<template>
  <v-main>
    <AppMenu :links="false"></AppMenu>
    <router-view/>
  </v-main>
</template>

<script>
import AppMenu from "@/components/landings/AppMenu";

export default {
  name: 'Login',
  components: {
    AppMenu,
  }
}
</script>
