import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    redirect: "/"
  },

  {
    path: '/',
    name: 'MainPageLanding',
    component: () => import(/* webpackChunkName: "MainPageLanding" */ '@/views/landings/MainPageLanding'),
    meta: {
      title: 'Roxie',
      layout: 'landing',
    }
  },

  {
    path: '/how-it-works',
    name: 'HowItWorksLanding',
    component: () => import(/* webpackChunkName: "HowItWorksLanding" */ '@/views/landings/HowItWorksLanding'),
    meta: {
      title: 'How it works',
      layout: 'landing',
    }
  },

  {
    path: '/catalog',
    name: 'CatalogLanding',
    component: () => import(/* webpackChunkName: "CatalogLanding" */ '@/views/landings/CatalogLanding'),
    meta: {
      title: 'Roxie Catalog',
      layout: 'landing',
    }
  },

  {
    path: '/why-us',
    name: 'WhyUsLanding',
    component: () => import(/* webpackChunkName: "WhyUsLanding" */ '@/views/landings/WhyUsLanding'),
    meta: {
      title: 'Why Us',
      layout: 'landing',
    }
  },

  {
    path: '/products',
    name: 'Products',
    redirect: {name: 'CatalogLanding'},
  },

  {
    path: '/products/:id',
    name: 'ProductItem',
    component: () => import(/* webpackChunkName: "WhyUsLanding" */ '@/views/landings/ProductItemPage'),
    meta: {
      layout: 'landing',
    }
  },

  {
    path: '/terms-of-use',
    name: 'TermsOfUsePage',
    component: () => import(/* webpackChunkName: "TermsOfUsePage" */ '@/views/TermsOfUsePage'),
    meta: {
      title: 'Roxie Terms of Use',
      layout: 'landing',
    }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicyPage',
    component: () => import(/* webpackChunkName: "TermsOfUsePage" */ '@/views/PrivacyPolicyPage'),
    meta: {
      title: 'Roxie Privacy Policy',
      layout: 'landing',
    }
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "LoginPage" */ '@/views/login/LoginPage'),
    meta: {
      title: 'Login',
      layout: 'login',
    }
  },

  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "LoginPage" */ '@/views/login/LoginPage'),
    meta: {
      title: 'Register',
      loginType: 'register',
      layout: 'login',
    }
  },

  {
    path: '/register/questions',
    name: 'RegisterQuestions',
    component: () => import(/* webpackChunkName: "LoginQuestions" */ '@/views/login/LoginQuestions'),
    meta: {
      title: 'Tell us about you',
      layout: 'login',
    }
  },

  {
    path: '/select-platform',
    name: 'SelectPlatform',
    component: () => import(/* webpackChunkName: "LoginSelectPlatform" */ '@/views/login/LoginSelectPlatform'),
    meta: {
      title: 'Connect Roxie to your e-commerce platform',
      layout: 'login',
    }
  },

  // {
  //   path: '/connect-to-shopify',
  //   name: 'ConnectToShopify',
  //   component: () => import(/* webpackChunkName: "LoginConnectToShopify" */ '@/views/login/LoginConnectToShopify'),
  //   meta: {
  //     title: 'Connect Roxie to Shopify',
  //     layout: 'login',
  //   }
  // },

  {
    path: '/logout',
    name: 'Logout',
  },

];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: to => {
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
    return {x: 0, y: 0}
  },
  routes,
});

router.beforeEach((to, from, next) => {
  if (['RegisterQuestions', 'SelectPlatform'].includes(to.name) && !store.state.auth.userInfo.email) {
    next({name: 'Register'});
  } else if (to.name === 'Logout') {
    store.commit('CLEAR_USER_INFO');
    next({name: 'MainPageLanding'});
  } else if (store.state.auth.userInfo.api_token && to.name !== 'SelectPlatform') {
    // Potential security issue.
    // If in future we will have several platform to connect,
    // user will have opportunity to change selected platform after he already connect to selected platform.
    store.dispatch('SEND_USER_TO_SHOPIFY');
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta?.title || to?.name || to?.fullPath?.replace("/", "");
});

export default router;
