import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        'primary': '#F20D55',
        'grey-1': '#333333',
        'grey-2': '#7D7D7D',
        'grey-3': '#A5ACC5',
        'grey-4': '#C4C4C4',
        'grey-5': '#F6F6F9',
        'grey-6': '#F2F2F2',
        'grey-7': '#696F79',
        'dark-blue': '#1F306E',
        'purple': '#8F97B7',
        'light-blue': '#1644ED',
        'black': '#000000',
      }
    }
  }
});
