<template>
  <v-main>
    Default Layout
    <router-view/>
  </v-main>
</template>

<script>
export default {
  name: 'Default',
}
</script>
