<template>
  <section class="become-part-section">
    <div class="section-content">

      <div class="brushes">
        <div class="brushs_block brushs_block--1">
          <img class="blue_brush" :src="require('@/assets/img/landings/how-it-works/blue_brush.png')" alt="">
          <h2 class="section-header headline-blue">
            Become a part of the
          </h2>
        </div>
        <div class="brushs_block brushs_block--2">
          <img class="red_brush" :src="require('@/assets/img/landings/how-it-works/red_brush.png')" alt="">
          <div class="headline-red">#RoxieMovement</div>
        </div>
      </div>

      <v-btn
        dark
        depressed
        height="auto"
        color="primary"
        class="py-3 py-sm-4 px-5 become-part-reg-btn"
        :to="{name: 'Register'}"
      >
        <img class="mr-4" width="24" height="24" :src="require('@/assets/img/icons/ic-arrow-right-white.svg')" alt="">
        Start
      </v-btn>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BecomePartBanner',
}
</script>

<style lang="scss">
@import "~@/assets/scss/colors";

.become-part-section {
  padding: 100px 0;
  .section-content {
    padding: 58px 0 74px;
  }
  .section-header {
    font-size: 54px;
    span {
      font-size: 80px;
      line-height: 0.64;
    }
  }
  .become-part-reg-btn {
    font-size: 24px;
    display: block;
    margin: 0 auto;
    max-width: 203px;
  }
}

@media screen and (max-width: 600px) {
  .become-part-section {
    padding: 50px 0;
    .section-header {
      font-size: 38px;
      span {
        font-size: 50px;
        line-height: 0.64;
      }
    }
    .become-part-reg-btn {
      font-size: 16px;
    }
  }
}

.blue_brush {
  width: 100%;
  height: 200px;
}
.red_brush {
  width: 100%;
  height: 230px;
}
.brushs_block {
  position: relative;
  display: flex;
  align-items: center;
  height: 166px;
  justify-content: flex-end;

  .headline-blue {
    font-family:'DM Serif Display', sans-serif;
    font-weight: normal;
    font-size: 54px;
    line-height: 74px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    top: 50%;
    right: 60px;
    padding: 0 0 12px 24px;
    transform: translateY(-50%) rotate(-1.14deg);

    @media screen and (max-width: 1440px) {
      font-size: 44px;
    }
    @media screen and (max-width: 1060px) {
      font-size: 34px;
      line-height: 100%;
    }
    @media screen and (max-width: 600px) {
      font-size: 24px;
      right: 32px;
    }
  }

  .headline-red {
    font-family: 'MarkerMark', sans-serif;
    font-weight: normal;
    font-size: 144px;
    line-height: 50px;
    text-align: center;
    color: #FFFFFF;
    position: absolute;
    left: 60px;
    padding: 0 24px 12px 0;

    @media (max-width: 1440px) {
      font-size: 100px;
    }
    @media (max-width: 1060px) {
      font-size: 80px;
    }
    @media (max-width: 600px) {
      font-size: 40px;
      top: 28%;
      left: 20px;
      right: 32px;
    }

  }
  &--1,
  &--2 {
    @media (max-width: 600px) {
      height: 124px;
      .blue_brush,
      .red_brush {
        height: 124px;
      }
    }
  }
}


.brushs_block--1 {
  width: 53%;
  margin-right: auto;
  @media (max-width: 600px) {
    width: 74%;
  }
}

.brushs_block--2 {
  position: absolute;
  width: 66%;
  right: 0;
  justify-content: flex-start;
  bottom: 2%;
  @media (max-width: 600px) {
    width: 74%;
  }
}

.brushes {
  height: 166px;
  position: relative;
  width: 100%;
  padding-bottom: 270px;
  margin-bottom: 100px;
  @media (max-width: 600px) {
    padding-bottom: 194px;
    margin-bottom: 50px;
  }
}

@media screen and (min-width: 1px) and (max-width: 1440px) {
  .become-part-section {
    overflow-x: hidden;
  }
}
</style>
