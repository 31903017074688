<template>
  <footer class="app-footer">
    <div class="footer-content mx-auto">
      <div class="footer-content-row d-flex flex-wrap align-center justify-space-between mb-16">
        <router-link :to="{name: 'MainPageLanding'}" class="shrink logo">
          <v-img
            alt="Vuetify Logo"
            contain
            :src="require('@/assets/img/logo-white.svg')"
            style="margin-top: -5px;"
          />
        </router-link>

        <ul class="app-footer-social-links footer-list d-flex flex-wrap justify-center align-center pa-0">
          <li v-for="(link, idx) in socialLinks" :key="idx">
            <a :href="link.url" :title="link.name" target="_blank" class="d-block">
              <img :width="19" :src="require(`@/assets/img/icons/${link.icon}.svg`)" alt="">
            </a>
          </li>
        </ul>
      </div>

      <div class="footer-content-row d-flex flex-wrap align-end justify-space-between mt-3">
        <ul class="footer-list pa-0">
          <li v-for="(link, idx) in landingsLinks" :key="idx" class="mt-3">
            <router-link :to="{name: link.routeName}" class="text-uppercase white--text">{{link.name}}</router-link>
          </li>
        </ul>
        <div class="app-footer-legal">
          <p class="mb-0 grey-2--text mb-3">All rights reserved. Copyright © 2021 Roxie.</p>
          <ul class="footer-list d-flex flex-wrap justify-center align-center pa-0">
            <li v-for="(link, idx) in legalLinks" :key="idx" class="ml-3 link-to">
              <router-link v-if="link.name !== 'Support'" :to="{name: link.routeName}" class="text-capitalize white--text pl-3">{{link.name}}</router-link>
<!--              <a v-else role="button" class="text-capitalize white&#45;&#45;text pl-3" @click="help">{{link.name}}</a>-->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data: () => ({
    socialLinks: [
      {name: 'Facebook', icon: 'ic-facebook', url: '#'},
      {name: 'Twitter', icon: 'ic-twitter', url: '#'},
      {name: 'Instagram', icon: 'ic-instagram', url: '#'},
      {name: 'YouTube', icon: 'ic-youtube', url: '#'},
    ],
    landingsLinks: [
      {name: 'Why Us', routeName: 'WhyUsLanding'},
      {name: 'Catalog', routeName: 'CatalogLanding'},
      {name: 'How it works', routeName: 'HowItWorksLanding'},
    ],
    legalLinks: [
      {name: 'Privacy Policy', routeName: 'PrivacyPolicyPage'},
      {name: 'Terms & Conditions', routeName: 'TermsOfUsePage'},
      {name: 'Support', routeName: 'MainPageLanding'},
      {name: 'Account', routeName: 'MainPageLanding'},
      {name: 'Subscribe', routeName: 'MainPageLanding'},
    ]
  })
}
</script>

<style lang="scss">
.app-footer {
  padding: 35px 100px 40px;
  background: #1D262D;

  .footer-content {
    position: relative;
    max-width: 1023px;
    width: 100%;
  }

  .footer-list {
    list-style-type: none;

    &:not(.app-footer-social-links) {
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .link-to {
    a {
      font-size: 14px !important;
      line-height: 24px;
    }
  }

  .app-footer-social-links a {
    margin-left: 43px;
  }

  .app-footer-legal {

    p {
      font-size: 14px;
      line-height: 24px;
      text-align: right;
    }

    a {
      position: relative;
      font-size: 18px;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 12px;
        width: 1px;
        background-color: #7D7D7D;
      }
    }

    li:first-child {
      a::before {
        display: none;
      }
    }
  }

  .logo {
    width: 120px;
    height: 35px;
  }

  li {
    font-size: 14px;
    line-height: 16px !important;
  }
}

@media screen and (max-width: 800px) {
  .app-footer {
    .footer-content {
      .app-footer-social-links {
        margin-top: 48px;
        a {
          margin: 0 48px 0;
        }
      }

      .footer-content-row {
        text-align: center;
        flex-direction: column;
        align-items: center!important;
      }

      .app-footer-legal {
        margin-top: 68px;
        padding-bottom: 12px;
        li {
          margin-right: 12px;
          margin-top: 12px;
        }
        a::before {
          display: none;
        }
        p {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .app-footer {
    padding: 35px 66px 40px;
  }
  .app-footer .footer-content .app-footer-social-links a {
    margin: 0 18px 0;
  }
}
</style>
