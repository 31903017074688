import {registerUser, loginUser} from "@/api/authorizatios.api";
import router from "../../router";

export default {
  state: {
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(atob(localStorage.getItem('userInfo'))) : null ?? {},
    userEmail: '',
    // shopify_auth_token: '',
    errorMessage: '',
  },
  getters: {
    // getShopifyAuthToken: state => state.shopify_auth_token,
    getUserInfo: state => state.userInfo,
    getErrorMessage: state => state.errorMessage,
  },
  mutations: {
    SET_USER_EMAIL(state, payload) {
      state.userEmail = payload;
    },
    SET_USER_INFO(state, payload) {
      state.userInfo = {...state.userInfo, ...payload};
    },
    // SET_SHOPIFY_AUTH_TOKEN(state, payload) {
    //   state.shopify_auth_token = payload;
    // },
    SET_ERROR_MESSAGE(state, payload) {
      state.errorMessage = payload;
    },
    CLEAR_USER_INFO(state) {
      state.userInfo = {};
      localStorage.removeItem('userInfo');
    }
  },
  actions: {
    async REGISTER({commit, dispatch}, credentials) {
      try {
        const res = await registerUser(credentials);
        if (res.data.success) {
          commit('SET_USER_INFO', res.data.user);
          localStorage.setItem('userInfo', btoa(JSON.stringify(res.data.user)));
          // dispatch('SEND_USER_TO_SHOPIFY');
          router.push({ name: 'SelectPlatform' });
          return
        }
        commit('SET_ERROR_MESSAGE', res.data.error);
      } catch (error) {
        console.error(`Registration User failed with error: ${error.message}`);
      }
    },
    async LOGIN({commit, dispatch}, credentials) {
      try {
        const res = await loginUser(credentials);
        if (res.data.success) {
          commit('SET_USER_INFO', res.data.user);
          localStorage.setItem('userInfo', btoa(JSON.stringify(res.data.user)));
          dispatch('SEND_USER_TO_SHOPIFY');
          // router.push({ name: 'SelectPlatform' });
          return
        }
        commit('SET_ERROR_MESSAGE', res.data.error);
      } catch (error) {
        console.error(`Login user failed with error: ${error.message}`);
      }
    },

    SEND_USER_TO_SHOPIFY({state}) {
      localStorage.removeItem('userInfo');
      window.location.href = `https://app.joinroxie.com/companies?access_token=${state.userInfo.api_token}`;
    },

    // async GENERATE_SHOPIFY_AUTH_TOKEN({commit}) {
    //   const res = await loadConnectPage();
    //   const parser = new DOMParser();
    //   const html = parser.parseFromString(res.data, 'text/html');
    //   const token = html.querySelector('input[name="authenticity_token"]').value;
    //   commit('SET_SHOPIFY_AUTH_TOKEN', token);
    // },
  },
}
