<template>
  <v-app>
    <component :is="layout">
      <router-view/>
    </component>
  </v-app>
</template>

<script>
import Default from "@/layouts/Default";
import Landing from "@/layouts/Landing";
import Login from "@/layouts/Login";

export default {
  name: 'App',
  components: {
    Default,
    Landing,
    Login,
  },
  computed: {
    layout() {
      return this.$route.meta.layout ?? 'default';
    }
  },
};
</script>

<style lang="scss">
* {
  font-family: Flama, sans-serif;
  line-height: 1;
}
</style>
