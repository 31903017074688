<template>
  <v-main>
    <AppMenu></AppMenu>
    <Sidebar></Sidebar>
    <router-view></router-view>
    <BecomePartBanner></BecomePartBanner>
    <AppFooter></AppFooter>
  </v-main>
</template>

<script>
import AppMenu from "@/components/landings/AppMenu";
import Sidebar from "@/components/landings/Sidebar";
import BecomePartBanner from "@/components/landings/BecomePartBanner";
import AppFooter from "@/components/landings/AppFooter";

export default {
  name: 'Landing',
  components: {
    AppMenu,
    Sidebar,
    BecomePartBanner,
    AppFooter,
  }
}
</script>
